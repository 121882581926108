/// <reference path="../system/jquery-1.11.3.min.js" />
/// <reference path="../system/underscore.min.js" />

(function (Helper, $) {

	Helper.Url = {
		resolveUrl: function(url) {
			url = url || "";
			if (url.charAt(0) !== "~") return url;

			if (!Helper.Url.resolveUrl.appPath) {
				var meta = document.getElementById('meta.AppDomainAppVirtualPath');
				Helper.Url.resolveUrl.appPath = meta ? meta.getAttribute("content") : '/';
			}

			if (Helper.Url.resolveUrl.appPath === '/')
				return url.slice(1, url.length);
			else if (Helper.Url.resolveUrl.appPath.endsWith('/'))
				return Helper.Url.resolveUrl.appPath.slice(0, Helper.Url.resolveUrl.appPath.length - 1) + url.slice(1, url.length);
			else
				return Helper.Url.resolveUrl.appPath + url.slice(1, url.length);
		},

		redirectToAction: function(action, controller, options) {
			var url = window.Helper.Url.action(action, controller, options);
			window.location = url;
		},

		action: function(action, controller, options) {
			var url = '/' + controller + '/' + action;
			if (!!options.plugin) {
				url = '/' + options.plugin + url;
			}
			if (!!options.id) {
				url += '/' + options.id;
			}
			return window.Helper.resolveUrl('~' + url);
		},

		qualifyURL: function (url) {
			var el = document.createElement('div');
			el.innerHTML = '<a href="' + url.split('&').join('&amp;').split('<').join('&lt;').split('"').join('&quot;') + '">x</a>';
			return el.firstChild.href;
		},

		getURLParameter: function (parameter) {
			if (!parameter) {
				return null;
			}
			parameter = parameter.toLowerCase();
			var searchParameters = window.location.search && window.location.search.length > 1 ? Helper.Url.getParameters(window.location.search.substring(1)) : {};
			var hashParameters = window.location.hash && window.location.hash.length > 1 ? Helper.Url.getParameters(window.location.hash.substring(1)) : {};
			var parameters = $.extend(searchParameters, hashParameters);
			var result = null;
			Object.getOwnPropertyNames(parameters).forEach(function(param) {
				if (result == null && param.toLowerCase() == parameter) {
					result = parameters[param];
				}
			});
			if ($.isNumeric(result)) {
				var numericResult = parseFloat(result);
				if (!isNaN(numericResult)) {
					return numericResult;
				}
			}
			if (result === "True" || result === "true") {
				return true;
			}
			if (result === "False" || result === "false") {
				return false;
			}
			return result;
		},

		getParameters: function (paramString) {
			///<param name="paramString">The query string in the form of "a=3&b=5&c=7&c=9"</param>
			///<summary>Gets the parameters from a given string and returns an object</summary>
			///<example>getParameters("a=3&b=5&c=7&c=9"); //yields {a:3,b:5,c:[7,9]}</example>
			var params = {};
			var e, a = /\+/g, r = /([^&;=]+)=?([^&;]*)/g,
                d = function (s) { return decodeURIComponent(s.replace(a, " ")); };
			while (e = r.exec(paramString)) {
				var key = d(e[1]), value = d(e[2]);
				if (!params[key]) {
					params[key] = value;
				} else if (_.isArray(params[key])) {
					params[key][params[key].length] = value;
				} else {
					params[key] = [params[key], value];
				}
			}
			return params;
		},

		getCriteriaFromUrl: function (criteriaId) {
			///<summary>Gets the criteria from the hash and the query string
			///	<para>Does so by passing them to getParameters function and then merging the results.</para>
			///</summary>
			var searchString = window.location.search !== "" && window.location.search !== undefined ? window.location.search.substring(1) : "";
            if (!!criteriaId) {
                if (searchString.indexOf(criteriaId) === -1) {
                    searchString = "";
                } else {
                    searchString.split("CID_List=").forEach(function(part) {
                        if (part.match("^" + criteriaId)) {
                            searchString = "CID_List=" + part;
                        }
                    });
                }
            }
			var hash = window.location.hash.substring(1);
			if (hash.indexOf("?") !== -1) {
				hash = hash.substr(hash.indexOf("?") + 1);
			}
			return $.extend(Helper.Url.getParameters(searchString), Helper.Url.getParameters(hash));
		},

		saveCriteriaToUrl: function (criteria, push, criteriaId) {
			///<summary>
			///		Puts the criteria to query string (or to the hash if there's
			///		no History API), without reloading the page
			///</summary>
			///<param name="criteria">The key-value pair for criteria (Object)</param>
			///<param name="push">
			///		If set to true, it will create an additional history entry.
			///		Please keep in mind that if there is no History API support,
			///		the additional entry will be created regardless of this parameter.
			///</param>
			var newCriteria = Helper.Object.removeEmptyParams(criteria);
			
			//We know better than History.js... Force the refresh.
			delete newCriteria["_suid"];
			
		    var urlCriteria = Helper.Url.getCriteriaFromUrl();
			var serialized = $.param(newCriteria, true);
            if (_.isEqual(urlCriteria, newCriteria)) {
				return;
			}
            if (!!criteriaId) {
                var newSerialized = "";
                var searchString = window.location.search.substring(1);
                searchString.split("CID_List=").forEach(function (part) {
                    if (!part.match("^" + criteriaId) & !!part) {
                        newSerialized += "CID_List=" + (part.endsWith("&") ? part : part + "&");
                    }
                });
                newSerialized += "CID_List=" + criteriaId + "&" + serialized;
                serialized = newSerialized;
            }
			if (serialized.length > 0) {
				serialized = "?" + serialized;
			}
			else if (window.location.search.length < 2) {
				return;
			}
			if (!!push) {
				window.history.pushState({ serializedCriteria: serialized }, document.title, serialized);
			}
			else {
				window.history.replaceState({ serializedCriteria: serialized }, document.title, serialized);
			}

			// won't work in internet explorer... will reload the page after the hash is added/modified
			//			else if (serialized !== "" && serialized !== window.location.hash) {
			//				window.location.hash = serialized;
			//				if(window.location.search !== "")
			//					window.location.search = "";
			//			}
		},

		prependHttp: function (url) {
			return /^https?:\/\//i.test(url) ? url : 'http://' + url;
		},

		reloadAndReturnToLocation: function() {
			window.history.replaceState(null, null, window.location.origin + window.location.pathname + "#/Main/Home/Startup?sync=true&redirectUrl=" + encodeURIComponent(window.location.hash.substr(1)));
			$(document).trigger("hashchange");
		}
	};

})((window.Helper = window.Helper || {}), jQuery);