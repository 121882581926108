/// <reference path="../system/jquery-1.11.3.min.js" />
/// <reference path="Helper.Url.js" />
(function (Helper, $) {
	Helper.User = {
		getCurrentUserName: function() {
			var userElement = document.getElementById("meta.CurrentUser");
			if (userElement) {
				return userElement.content;
			}
			return null;
		},
		getCurrentUser: function () {
			var url = Helper.Url.resolveUrl("~/Users/CurrentUser.json");
			return $.ajax(url, { appendTokenIfRequired: true });
		},
		getDisplayName: function(user) {
			var u = window.ko.toJS(user);
			if (u === null) {
				return Helper.String.getTranslatedString("Unspecified");
			}
			var tokens = [];
			if (u.LastName) {
				tokens.push(u.LastName);
			}
			if (u.FirstName) {
				tokens.push(u.FirstName);
			}
			if (tokens.length < 2 && u.Email) {
				tokens.push(u.Email);
			}
			return tokens.join(", ");
		},
		filterUserQuery: function (query, term, userGroupId) {
			if (term) {
				query = query.filter(function(it) {
						return it.FirstName.toLowerCase().contains(this.term) ||
							it.LastName.toLowerCase().contains(this.term)||
							it.Id.toLowerCase().contains(this.term);
					},
					{ term: term });
			}
			if (userGroupId) {
				userGroupId = userGroupId.Value || userGroupId;
				if (window.database.storageProvider.name === "webSql") {
					query = query.filter(function(it) {
						return it.UsergroupIds.contains(this.userGroupId);
					}, { userGroupId: '"' + userGroupId + '"' });
				} else {
					query = query.filter("it.UsergroupObjects.some(function(it2) { return it2.Id === this.userGroupId; })", { userGroupId: userGroupId });
				}
			}
			query = query.filter(function(it) {
				return it.Discharged === false;
			});
			return query;
		},
		filterUsergroupQueryById: function (query, term) {
			if (term) {
				query = query.filter(function(it) {
					return it.Id === this.term;
					},
					{ term: term });
			}
			return query;
		},
		mapForSelect2Display: function (user) {
			return {
				id: user.Id,
				item: user,
				text: Helper.User.getDisplayName(user)
			};
		},
		mapUsergroupForSelect2Display: function (usergroup) {
					return {
						id: usergroup.Id,
						item: usergroup,
						text: usergroup.Name
					};
		}
	};

})((window.Helper = window.Helper || {}), jQuery);